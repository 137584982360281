import React from "react"
import Top from "../pageComponents/Top"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { remarkForm } from "gatsby-tinacms-remark"
import { graphql } from "gatsby"

export interface PageProp {
  location: Location
}

const IndexPage: React.FC<PageProp> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="VantagePoint | Asia's Premier Executive Search" />
      <Top />
    </Layout>
  )
}

const JobPostForm = {
  label: "Notifications",
  actions: [],
  /**
   * The list of fields tell us what the form looks like.
   */
  fields: [
    {
      name: "frontmatter.notificationTitle",
      component: "text",
      label: "Notification Title",
    },
    {
      name: "frontmatter.notificationDescription",
      component: "textarea",
      label: "Notification Description",
    },
    {
      name: "frontmatter.showNotification",
      component: "toggle",
      label: "Show Notification",
      required: true,
    },
    {
      name: "frontmatter.releaseTitle",
      component: "text",
      label: "Release Title",
      required: true,
    },
    {
      name: "frontmatter.releaseDescription",
      component: "textarea",
      label: "Release Description",
      required: true,
    },
  ],
}

export default remarkForm(IndexPage, JobPostForm)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(
      fileRelativePath: { eq: "/content/banners/notifications.md" }
    ) {
      ...TinaRemark
      frontmatter {
        notificationTitle
        notificationDescription
        showNotification
        releaseTitle
        releaseDescription
      }
    }
  }
`
