import React from "react"
import Hero from "./Hero"
import WhoWeWorkWith from "./WhoWeWorkWith"
import RecentPositions from "../../components/RecentPositions"
import TheTeamBehind from "./TheTeamBehind"
import { CallToAction } from "../../components/CallToAction"
import { FadeIn } from "../../components/animations/FadeIn"
import { useLang } from "../../components/Header/LanguageToggle/useLang"
import { Languages } from "../../provider"
import { EmailType } from "../../utils/sendEmail"

const Page = () => {
  const lang = useLang()
  return (
    <div>
      <Hero />
      <div className="z-10">
        <WhoWeWorkWith />
      </div>
      <div className="z-0">
        <RecentPositions />
      </div>
      <TheTeamBehind />
      <CallToAction
        title="Need Help?"
        text={
          lang === Languages.EN
            ? "Enter your details and we will get in touch soon."
            : "お問合せ内容のご記入をお願いします。"
        }
        type={EmailType.default}
      />
    </div>
  )
}

export default Page
