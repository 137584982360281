import React from "react"

interface Props {
  color?: string
  width?: number
  noMargin?: boolean
  thickness?: number
  flipped?: boolean
}

export const Arrow: React.FC<Props> = ({
  color = "#000",
  width = 30,
  flipped,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.21 27.6"
      style={{ width: width, transform: flipped ? "scaleX(-1)" : "" }}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon
            fill={color}
            points="0.92 27.6 0.01 26.05 20.68 13.93 0 1.54 0.93 0 24.21 13.95 0.92 27.6"
          />
        </g>
      </g>
    </svg>
  )
}
