import React, { useState } from "react"
import { useLang } from "../../../components/Header/LanguageToggle/useLang"
import { Languages } from "../../../provider"
import { Arrow } from "../../../components/atoms/Arrow"
import {
  useIsMobile,
  useDimensions,
} from "../../../components/hooks/useDimensions"
import { pages } from "../../../constants"
import { Link } from "gatsby"
import Video from "./video"

const Text = (loaded: boolean) => ({
  [Languages.EN]: (
    <p
      className={`max-w-md mt-4 font-serif text-2xl leading-tight tracking-tight sm:leading-normal text-shadow-sm ${loaded &&
        "text-gray-100"}`}
      style={{ transition: "color 600ms ease 300ms" }}
    >
      VantagePoint assists professionals and businesses achieve strategic, long
      term, organizational growth.
    </p>
  ),
  [Languages.JP]: (
    <p
      className={`w-full sm:max-w-lg mt-4 mr-16 text-lg sm:text-xl tracking-tight leading-tight sm:leading-snug font-jp-serif sm:mr-0 text-shadow-sm ${loaded &&
        "text-gray-100"}`}
      style={{ transition: "color 600ms ease 300ms" }}
    >
      私たちは、採用企業様と候補者様の信頼できるキャリア戦略パートナーとして、双方にとって、より有意義な道を見出す支援をいたします。
    </p>
  ),
})

const HeroText: React.FC<{ loaded: boolean }> = ({ loaded }) => {
  const lang = useLang()
  const isMobile = useIsMobile()

  const desktop = (
    <h1
      className={`max-w-lg leading-none ${loaded &&
        "text-gray-100"} text-shadow-md`}
      style={{ fontSize: 74, transition: "color 600ms ease 300ms" }}
    >
      Recruit from a <br />
      new VantagePoint.
    </h1>
  )

  const mobile = (
    <h1
      className={`max-w-lg leading-none ${loaded &&
        "text-gray-100"} text-shadow-sm`}
      style={{ fontSize: 60, transition: "color 600ms ease 300ms" }}
    >
      Recruit from <br /> a new VantagePoint.
    </h1>
  )

  return (
    <div className="z-10">
      <Link to={pages.aboutUs}>
        {isMobile ? mobile : desktop}
        {Text(loaded)[lang] || Text(loaded)[Languages.EN]}
        <div className="mt-6">
          <Arrow width={30} color="#f7fafc" />
        </div>
      </Link>
    </div>
  )
}

const Hero = () => {
  const { width } = useDimensions({ width: 300, height: 700 })
  // client side guard needed for suspense:
  // https://www.gatsbyjs.org/docs/using-client-side-only-packages/
  const isSSR = typeof window === "undefined"
  const [loaded, setLoaded] = useState(false)
  return (
    <div
      className="relative flex items-center px-6 overflow-hidden xs:pl-10 sm:pl-16"
      style={{
        transition: "height 300ms ease-in",
        height: width < 600 ? "600px" : "750px",
      }}
    >
      <HeroText loaded={true} />
      <Video />
    </div>
  )
}

export default Hero
