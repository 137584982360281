import React, { useEffect, useState } from "react"
import Img from "gatsby-image"

import desktopVidSrc from "../../../assets/videos/desktop.mp4"
import mobileVidSrc from "../../../assets/videos/mobile.mp4"

import { useDimensions } from "../../../components/hooks/useDimensions"
import { WIDTH_BREAKPOINT } from "../../../constants"
import { useStaticQuery, graphql } from "gatsby"

const Image: React.FC<{ large?: boolean }> = ({ large }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "desktop_screenshot.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className="absolute bottom-0 left-0 z-0 w-full h-full opacity-0 sm:opacity-100">
      <Img
        loading="eager"
        durationFadeIn={200}
        fluid={data.placeholderImage.childImageSharp.fluid}
        style={{ top: large ? -150 : 0 }}
        className={large ? "h-full" : ""}
      />
    </div>
  )
}

interface Video {
  onReady?: () => {}
}

const Video: React.FC<Video> = ({ onReady }) => {
  const { width } = useDimensions({ width: 300, height: 600 })
  const isMobile = width < WIDTH_BREAKPOINT
  const isMobileVideo = width < 800

  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    return () => setIsReady(false)
  }, [])
  const handleReady = () => {
    setIsReady(true)
  }

  return (
    <div
      className="absolute h-full"
      style={{
        width: "100%",
        zIndex: -1,
        top: width > 1200 ? -150 : 0,
        ...(!isMobile && { right: 0 }),
        ...(isMobile && { left: 0 }),
      }}
    >
      {!isReady && <Image />}
      <video
        className={`object-cover`}
        autoPlay
        muted
        loop
        playsInline
        style={{
          ...(isMobile && { height: "100%" }),
          ...(!isMobile && { width: "100%" }),
        }}
        src={`${isMobileVideo ? mobileVidSrc : desktopVidSrc}`}
        onLoadedMetadata={handleReady}
      >
        {/* <source
          src={`${isMobileVideo ? mobileVidSrc : desktopVidSrc}`}
          type="video/mp4"
        />
        Your browser does not support the video tag. */}
      </video>
    </div>
  )
}

export default Video
