import React, { useState } from "react"
import { Title } from "../../../components/atoms/Title"
import { Arrow } from "../../../components/atoms/Arrow"
import { vpBlue } from "../../../assets/colors"
import { Link } from "gatsby"

import img1 from "../../../images/01.jpg"
import img2 from "../../../images/02.jpg"
import img3 from "../../../images/03.jpg"
import img4 from "../../../images/04.jpg"
import img5 from "../../../images/05.jpg"
import img6 from "../../../images/06.jpg"
import img7 from "../../../images/07.jpg"
import { FadeIn, FadeInBase } from "../../../components/animations/FadeIn"
import { clampedRandom } from "../../../utils"
import InView, { useInView } from "react-intersection-observer"
import {
  useIsMobile,
  useDimensions,
} from "../../../components/hooks/useDimensions"
import { useLang } from "../../../components/Header/LanguageToggle/useLang"
import { Languages } from "../../../provider"
import { motion } from "framer-motion"

const SPACING = 1

enum MosaicType {
  none = "none",
  blue = "vp-blue",
  gray = "vp-gray",
  img = "img",
}

interface MosaicProps {
  type?: MosaicType
  size?: number
  src?: string
  animate?: boolean
  inView?: boolean
}

const Square: React.FC<MosaicProps> = ({
  type = MosaicType.none,
  src,
  size = 100,
  animate,
  inView,
}) => {
  const [hovered, setHovered] = useState(false)

  const Blank = ({ size = 100, color = MosaicType.none }) => {
    return (
      <div className={`bg-${color}`} style={{ height: size, width: size }}>
        {" "}
      </div>
    )
  }

  const Ppl = ({ size = 100, src = "" }) => {
    const onHoverStart = () => setHovered(true)
    const onHoverEnd = () => setHovered(false)
    return (
      <div
        key={src}
        style={{
          height: size,
          width: size,
          overflow: "hidden",
          filter: hovered ? "grayscale(0%)" : "grayscale(100%)",
          transition: "all 500ms ease",
        }}
      >
        <motion.img
          src={src}
          onHoverStart={onHoverStart}
          onHoverEnd={onHoverEnd}
        />
      </div>
    )
  }

  const OutputSquare = () => {
    switch (type) {
      case MosaicType.img:
        return <Ppl size={size} src={src} />
      default:
        return <Blank size={size} color={type} />
    }
  }

  return animate ? (
    <FadeInBase inView={inView!} delay={clampedRandom(0.9)} y={0}>
      <OutputSquare />
    </FadeInBase>
  ) : (
    <OutputSquare />
  )
}

const TeamBehindImgs: React.FC<{ mobile: boolean }> = ({ mobile }) => {
  const [ref, inView, entry] = useInView({ threshold: 0.2 })

  return (
    <div
      ref={ref}
      style={{
        display: "grid",
        gridTemplateColumns: mobile
          ? "1fr 1fr 1fr 1fr"
          : "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
        gridTemplateRows: "1fr 1fr 1fr 1fr",
        width: "max-content",
      }}
    >
      {/* first row */}
      <Square />
      <Square animate inView={inView} type={MosaicType.img} src={img6} />
      <Square />
      {mobile ? (
        <Square />
      ) : (
        <Square animate inView={inView} type={MosaicType.img} src={img7} />
      )}
      {!mobile && (
        <>
          <Square />
          <Square animate inView={inView} type={MosaicType.blue} />
          <Square />
        </>
      )}

      {/* second row */}
      <Square animate inView={inView} type={MosaicType.gray} />
      <Square animate inView={inView} type={MosaicType.img} src={img3} />
      <Square animate inView={inView} type={MosaicType.blue} />
      <Square animate inView={inView} type={MosaicType.gray} />
      {!mobile && (
        <>
          <Square animate inView={inView} type={MosaicType.img} src={img2} />
          <Square />
          <Square />
        </>
      )}

      {/* third row */}
      <Square />
      <Square animate inView={inView} type={MosaicType.blue} />
      <Square />
      <Square animate inView={inView} type={MosaicType.img} src={img1} />
      {!mobile && (
        <>
          <Square animate inView={inView} type={MosaicType.blue} />
          <Square animate inView={inView} type={MosaicType.img} src={img4} />
          <Square />
        </>
      )}

      {/* fourth row */}
      <Square />
      <Square />
      <Square animate inView={inView} type={MosaicType.img} src={img5} />
      <Square />
      {!mobile && (
        <>
          <Square />
          <Square />
          <Square animate inView={inView} type={MosaicType.blue} />
        </>
      )}
    </div>
  )
}

interface Props {
  lang: Languages
}

const TeamBehindText: React.FC<Props> = ({ lang }) => {
  const Text = {
    [Languages.EN]: (
      <span>
        VantagePoint helps professionals and leading companies alike find
        merging paths that align their career and organizational goals.
      </span>
    ),
    [Languages.JP]: (
      <small>
        私たちは、採用企業様と候補者様の信頼できるキャリア戦略パートナーとして、双方にとって、より有意義な道を見出す支援をいたします。
      </small>
    ),
  }
  return (
    <div className="flex flex-col items-start justify-start mt-16 sm:pl-10 lg:pl-6 lg:mt-0 ">
      <Title>
        <span className="text-vp-blue">The team</span>
      </Title>
      <h3 className="mt-6 leading-tight sm:max-w-xs h3-serif">{Text[lang]}</h3>
      <div className="mt-6">
        <Arrow width={30} />
      </div>
    </div>
  )
}

const JobSeekers: React.FC<Props> = ({ lang }) => {
  const Text = {
    [Languages.EN]: (
      <span>
        Today’s career paths are not linear, predictable 40-year long tunnels
        rather a series of strategic decisions. Here at VantagePoint, we don’t
        focus on your next move alone but help design your mid and long-term
        career strategy.
      </span>
    ),
    [Languages.JP]: (
      <small>
        近年のキャリアパスは複雑に分岐していると言えます。​
        <br />
        バンテージポイントは、単に現在のキャリアを活かせる転職先を紹介するのではなく、５年先、10年先、20年先に描く理想的なキャリア到達に向けた戦略的なキャリアパスのサポートを行っています。​
      </small>
    ),
  }
  return (
    <FadeIn
      delay={0}
      classes="flex h-full items-center bg-white p-10 border-2 rounded-lg hover:shadow-xl w-full"
      style={{ transition: "box-shadow 300ms ease" }}
    >
      <div>
        <Title>
          <span className="text-vp-blue">
            Job <br /> Seekers
          </span>
        </Title>
        <div className="mt-6">
          <Arrow color={vpBlue} width={30} />
        </div>
      </div>
      <p className="ml-8 leading-snug tracking-tight test-gray-900">
        {Text[lang]}
      </p>
      <br />
      <br />
    </FadeIn>
  )
}

const HiringManagers: React.FC<Props> = ({ lang }) => {
  const Text = {
    [Languages.EN]: (
      <span>
        Recruitment is not as straightforward as it used to be. VantagePoint
        designed a bespoke, holistic recruiting approach challenging
        conventional wisdom and stereotypical images of what top talent look
        like and where they come from.
      </span>
    ),
    [Languages.JP]: (
      <small>
        組織に変革をもたらす実力のある人材を採用するためには、スキルや経験といった候補者様の客観的な事実だけではなく、その方と向き合うことで浮かび上がってくる性格や価値観といった内面的な要素も含め、総合的に見極めることが重要であると考えています。
      </small>
    ),
  }
  return (
    <FadeIn
      delay={0.2}
      classes="flex h-full items-center bg-white p-10 border-2 rounded-lg hover:shadow-xl w-full"
      style={{ transition: "box-shadow 300ms ease" }}
    >
      <div>
        <Title>
          <span className="text-vp-blue">
            Hiring <br /> Managers
          </span>
        </Title>
        <div className="mt-6">
          <Arrow color={vpBlue} width={30} />
        </div>
      </div>
      <p className="ml-8 leading-snug tracking-tight test-gray-900">
        {Text[lang]}
      </p>
      <br />
      <br />
    </FadeIn>
  )
}

const Cards = () => {
  const { width } = useDimensions()
  const isMobile = useIsMobile()
  const lang = useLang()
  return (
    <div className="w-full mb-20 -mt-16 sm:-mt-24">
      <div className="flex flex-col justify-center pt-10 mx-6 bg-white xs:mx-10 sm:mx-16 sm:pt-24 lg:flex-row">
        <TeamBehindImgs mobile={isMobile} />
        <Link to="/about#theteam">
          <FadeIn delay={0} y={0}>
            <TeamBehindText lang={lang} />
          </FadeIn>
        </Link>
      </div>

      {!isMobile && (
        <div
          className="px-6 mt-20 xs:px-10 sm:px-16"
          style={{
            display: "grid",
            gridTemplateColumns: width < 1000 ? "100%" : "1fr 1fr",
            gridGap: 25,
          }}
        >
          <Link to="/talents">
            <JobSeekers lang={lang} />
          </Link>
          <Link to="/hiring-managers">
            <HiringManagers lang={lang} />
          </Link>
        </div>
      )}
    </div>
  )
}

export default Cards
